<template>
  <section class="page-header">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h1 class="title Nexa-Bold">{{title}}</h1>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: 'ProjectCard',
      props: {
      title: String
    }
  }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped="" lang="scss">
  @import '../assets/scss/custom.scss';
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  .page-header{
    padding-bottom: 50px;
    padding-top: 50px;
    .title{
      font-size: 40px;
      text-transform: uppercase;
    }
  }
  @include media-breakpoint-up(xs) {
  }
  @include media-breakpoint-up(sm) {
  }
  @include media-breakpoint-up(md) {
  }
  @include media-breakpoint-down(md) {
    .page-header{
      padding-bottom: 30px;
      padding-top: 30px;
      .title{
        font-size: 25px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
  }
</style>
